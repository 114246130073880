<style>

</style>

<script>
import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
export default {
  name: "BanReason",
  props: ['banForm', 'error'],
  validations: {
    banForm: {
      reason: {required, minLength: minLength(3), maxLength: maxLength(40)},
    },
  }
}
</script>
<template>
    <div class="form-group">
      <label for="banFormReason">{{ $t('banmanager.add.details.reason.label') }}</label>
      <input
          id="banFormReason"
          v-model="banForm.reason"
          type="text"
          class="form-control"
          :placeholder="$t('banmanager.add.details.reason.label')"
          :class="{ 'is-invalid': error }"
          v-on:keyup="$v.banForm.$touch();"
      />
      <div v-if="error" class="invalid-feedback">
        <span v-if="!$v.banForm.reason.required">{{ $t('banmanager.add.details.reason.required') }}</span>
        <span v-if="!$v.banForm.reason.minLength">{{ $t('banmanager.add.details.reason.minlength') }}</span>
        <span v-if="!$v.banForm.reason.maxLength">{{ $t('banmanager.add.details.reason.maxlength') }}</span>
      </div>
    </div>
</template>
